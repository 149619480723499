import Placeholder from './Placeholder.vue';
import SciOnline from './SciOnline.vue';
import CoreSciOnline from './CoreSciOnline.vue';
import PlusUsers from './UsePlus.vue';
import PlusEmails from './EmailsPlus.vue';
import PlusProperties from './PropertyPlus.vue';
import PlusTrialDays from './TrialsDays.vue';
import OldPlans from './OldPlans.vue';
import ConfigPlan from './Plan.vue';
import ChatBot from './ChatBot.vue';

export default {
    'placeholder': Placeholder,
    'sci-online': SciOnline,
    'core-sci-online': CoreSciOnline,
    'plus-users': PlusUsers,
    'plus-emails': PlusEmails,
    'plus-properties': PlusProperties,
    'plus-trial-days': PlusTrialDays,
    'sci-dekstop': OldPlans,
    'old-site': OldPlans,
    'chatbot': ChatBot,
    'config-plan': ConfigPlan
}