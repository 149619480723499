<template>
  <div class="row" ref="form">
    <div class="col-md-12" style="min-height: 140px">
      <div class="col-md-3">
        <p>Status: <strong>{{ statusText }}</strong></p>
        <p>
          Instalado:
          <strong>
            {{ installStatus }}
            <i :class="installClass"></i>
          </strong>
        </p>
        <p>Limite de leads:
          <strong>{{ clientProduct.managerConfigs['leadLimit'] }}</strong>
        </p>
        <p>Limite de conversas:
          <strong>{{ clientProduct.managerConfigs['conversationLimit'] }}</strong>
        </p>
      </div>
    </div>
    <div class="pull-right">
      <div class="col-md-12">
        <button @click="install" class="btn blue" type="button" v-show="!isInstalled">
          <i class="fa fa-cogs"></i>
          Instalar
        </button>
        <button type="button" class="btn btn-danger" @click="askUninstall" v-show="isInstalled">
          <i class="fa fa-trash"></i>
          Desinstalar
        </button>
        <button
            @click="changeActiveStatus($refs.form)"
            type="button"
            class="btn"
            :class="statusButtonClass"
            :disabled="!isInstalled"
            :title="!isInstalled ? 'Não é possível ativar o SDR Digital se ele não estiver instalado' : ''"
        >
          <i :class="statusButtonIcon"></i>
          {{ statusButtonText }}
        </button>
        <ui-modal v-if="ui.uninstallShow" @close="closeAskUninstall" width="500px">
          <div slot="header">Deletar Produto?</div>

          <div slot="body" class="text-center">
            <h1 class="text-danger">
              <strong>A operação será definitiva</strong>
            </h1>
            <h2 class="text-danger">Não é feito backup!</h2>
            <p>Ao deletar este produto todas as informações referentes ao SDR Digital serão apagadas.</p>
          </div>

          <div slot="footer">
            <button type="button" class="btn btn-default" @click="closeAskUninstall">Fechar</button>
            <button type="button" @click="performUninstall" class="btn btn-primary">Desinstalar</button>
          </div>
        </ui-modal>

      </div>
    </div>
  </div>
</template>

<script>
import mixin from "./mixin";

export default {
  name: "ChatBot",
  mixins: [mixin],
  data() {
    return {
      ui: {
        uninstallShow: false
      }
    }
  },
  methods: {
    askUninstall() {
      this.ui.uninstallShow = true;
    },
    closeAskUninstall() {
      this.ui.uninstallShow = false;
    },
    performUninstall() {
      this.uninstall().then(() => this.closeAskUninstall());
    }
  }

}
</script>

<style scoped>

</style>